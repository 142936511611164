<template>
    <div style="margin-bottom:2rem;">
        <div class="title1">
            <p class="title_top">LISI  NEWS</p>
            <p class="title_content">历思动态</p>
        </div>
        <div style="margin-bottom:2rem;">
            <div @click="go(item.id)" :class="'box_shadow col7-3 flex center wrap '+(index!=(data.length-1)?'item':'')" v-for="(item,index) in data" :key="item.id">
                <div class="col3 flex center" style="    
                    max-height: 222px;
                    height: 70%;
                    padding: 20px 0px;
                    min-height: 100px;">
                        <img style="    
                                width: 100%;
                                max-width: 285px;
                                height: inherit;
                                max-height: inherit;
                                min-height: inherit;" v-lazy="require('../../assets/news/ls/'+item.url)">
                </div>
                <div class="col7 flex center" style="text-align:left;min-height:273px">
                    <div class="col9" style="font-size:0.9rem;margin:0 auto">
                        <div class="space_content" style="font-size:1rem;margin-bottom: 0px;font-weight:bold;margin-top: 10px;">
                                {{item.title}}
                        </div>
                        <div class="space_content" style="font-size:1rem;text-align:right;color:#a7a7a7">
                                {{item.time}}
                        </div>
                        <div class="space_content" :key="index" v-for="(content,index) in item.content" :style="(index!=(content.length-1))?'margin-bottom: 10px;':''">
                            {{content}}
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <More></More>
    </div>
</template>
<script>
import More from "@/components/More.vue"
export default {
    components:{More},
    data(){
        return{
            data:[
                {id:1,url:'1-1.png',time:'2021-11-04',title:'历思战略合作单位——青岛中院领导莅临福建历思司法鉴定所调研指导！',content:['10月27日，历思战略合作单位——青岛市中级人民法院领导到福建历思司法鉴定所进行调研指导。座谈会上，历思负责人介绍了2020年以来受理的青岛法院司法鉴定案件的概况。双方就司法鉴定案件中存在的难点问题及下一步更深入的合作进行交流讨论...']},
                {id:2,url:'2-1.png',time:'2021-11-04',title:'庆贺！我所曾建晟主任当选厦门市司法鉴定协会监事长',content:['10月29日，厦门市司法鉴定协会第五届会员代表大会在市司法局召开。大会选举产生了新一届理事会成员和新一届领导班子，其中，福建历思司法鉴定所主任曾建晟当选为协会监事长。...']},
                {id:3,url:'3-1.png',time:'2021-11-29',title:'省创新研究院中科育成中心领导莅临历思调研',content:['11月22日，福建省创新研究院中科育成中心主任冯先伟一行莅临厦门历思科技服务有限公司（以下简称历思）开展工作调研，了解历思发展规划和技术需求。','历思领导向冯主任介绍了公司的基本情况。历思是一家拥有多专业物证优势技术的国家级高新技术企业，目前历思旗下有3家司法鉴定所、1家检测集团公司、1家生物科技公司、1家价值评估公司。历思自主研发的多类产品已申报并获批多项国内、国际发明专利...']},
            ]
        }
    },
    methods:{
        go(id){
            let pathStr = '/news/lsnews/'+id
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>
<style scoped>
.item{
    border-bottom: 1px solid #dddddd;
    margin-bottom: 2rem;
}
</style>